@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100&display=swap');

.apod {
    display: flex;
    flex-direction: row; 
    padding: 3vh 2vw;
    color: white;
    font-family: 'Roboto Mono', cursive;

    .image {
        width: 50%;
        max-height: 80vh;
        padding-right: 2vw;
        padding-left: 2vw;
    }

    .header {
        color: #ff9d1e;
    }
}


.title {
    text-align: center;
    padding-top: 2rem;
    font-size: 4rem;
    font-family: 'Orbitron';
    color: #d14f4f;
}